<template>
  <div class="row p-1 pt-0">
    <div class="col-6 pe-2">
      <file-uploader v-model="preview" :key="'preview-uploader-'+this._uid" label="Preview Image" type="image" folder="maestro" />
    </div>
    <div class="col-6 d-flex flex-column ps-0">
      <text-input type="text" v-model="name" label="Template Name" />
      <text-input type="text" v-model="componentFolder" label="Folder Name" :disabled="folder!==null" />
      <button class="vave-btn btn-blue w-100 mt-3" @click="uploadTemplate">Save Template</button>
    </div>
  </div>
</template>

<script>
import FileUploader from "./FileUploader";
import TextInput from "./../inputs/text-input";

export default {
  components: { FileUploader, TextInput },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    folder: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      name: this.value.label,
      preview: null,
      componentFolder: this.folder,
    };
  },
  methods: {
    uploadTemplate(value) {
      this.$emit("uploadTemplate", {
        config: value,
        name: this.name,
        folder: this.componentFolder.toLowerCase(),
        preview: this.preview,
      });
    },
  },
};
</script>

<style>
</style>
<template>
  <div class="templates">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="folders">
            <div class="folder" v-for="group, groupkey in templates" :key="'folder'+groupkey" :ref="'templates_folder_'+groupkey" @click="toggleFolder(group.folder)">
              <div class="title">
                <div class="icon icon-close" v-show="group.open === false">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                    <path d="M64 480H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H288c-10.1 0-19.6-4.7-25.6-12.8L243.2 57.6C231.1 41.5 212.1 32 192 32H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64z" />
                  </svg>
                </div>
                <div class="icon icon-open" v-show="group.open === true">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                    <!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                    <path
                      d="M384 480h48c11.4 0 21.9-6 27.6-15.9l112-192c5.8-9.9 5.8-22.1 .1-32.1S555.5 224 544 224H144c-11.4 0-21.9 6-27.6 15.9L48 357.1V96c0-8.8 7.2-16 16-16H181.5c4.2 0 8.3 1.7 11.3 4.7l26.5 26.5c21 21 49.5 32.8 79.2 32.8H416c8.8 0 16 7.2 16 16v32h48V160c0-35.3-28.7-64-64-64H298.5c-17 0-33.3-6.7-45.3-18.7L226.7 50.7c-12-12-28.3-18.7-45.3-18.7H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H87.7 384z"
                    />
                  </svg>
                </div>
                <span>{{group.folder}}</span>
              </div>
            </div>
            <div v-if="saveMode" class="folder" @click="addNewFolder()">
              <div class="title">
                <div class="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                      d="M448 96h-176L226.7 50.75C214.7 38.74 198.5 32 181.5 32H64C28.66 32 0 60.65 0 96v320c0 35.35 28.66 64 64 64h384c35.34 0 64-28.65 64-64V160C512 124.7 483.3 96 448 96zM480 416c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V96c0-17.64 14.36-32 32-32h117.5c8.547 0 16.58 3.328 22.62 9.375L258.7 128H448c17.64 0 32 14.36 32 32V416zM336 272h-64V207.1C272 199.2 264.8 192 256 192S240 199.2 240 207.1V272h-64C167.2 272 160 279.2 160 288c0 8.834 7.156 15.1 16 15.1h64v64C240 376.8 247.2 384 256 384c8.836 0 16-7.16 16-15.1V303.1h64C344.8 303.1 352 296.8 352 288S344.8 272 336 272z"
                    />
                  </svg>
                </div>
                <span>New folder</span>
              </div>
            </div>
          </div>
          <div class="group" v-for="group, groupkey in templates" :key="'group'+groupkey" :ref="'templates_group_'+groupkey" v-show="group.open === true">
            <div class="row" v-if="saveMode">
              <div class="col-auto" v-if="group.folder == 'default'">
                <div class="mt-4 alert alert-info">
                  <i class="fa-solid fa-cloud-question me-2"></i> Cannot save templates in the "Default" folder: please choose a different folder
                </div>
              </div>
              <div class="col-8 mb-3" v-else>
                <h5 class="mt-4">
                  Save current template in the folder
                  <span style="text-transform:capitalize;">"{{group.folder}}"</span>
                </h5>
                <TemplateUploader v-model="currentWidget" :folder="group.folder" @uploadTemplate="uploadTemplate($event)" />
              </div>
            </div>
            <div class="row">
              <div class="col-auto">
                <div v-if="saveMode" class="d-flex align-items-center toggle-templates" @click="toggleTemplates(group.folder)">
                  <span>Show templates in this folder</span>
                  <chevron :ref="'chevronicon_' + group.folder" />
                </div>
                <h5 v-else class="mt-4">
                  Choose a template frome the
                  <span style="text-transform:capitalize;">{{group.folder}}</span> folder
                </h5>
              </div>
            </div>
            <div class="templates-container" :class="{'expanded': !saveMode || group.expanded}">
              <div class="row">
                <div class="col-4 mb-3" v-for="template,k in group.components.slice().reverse()" :key="group.folder + k">
                  <div class="template" :class="{'save-mode': saveMode}" @click="chooseTemplate(template.config)">
                    <div class="preview-container">
                      <div class="preview" :data-template="template.config" :style="'--preview-url: url(' + image(template.preview ? template.preview : 'https://res.cloudinary.com/mstore/image/upload/v1679926131/vaveproxy/maestro/notfound_zw0rjh.jpg', 700) + ')'"></div>
                    </div>
                    <div class="overlay">Use this Template</div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <strong>{{template.name}}</strong>
                    </div>
                    <div class="col-auto" v-if="!template.read_only">
                      <div class="delete" @click.stop="deleteTemplate(template.id, group.folder)">
                        <i class="fas fa-trash"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-start" v-show="showAddNewFolder === true && saveMode">
            <div class="col-8">
              <h5 class="mt-4">Upload template in a new folder</h5>
              <TemplateUploader v-model="currentWidget" @uploadTemplate="uploadTemplate($event)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "./../../http.js";
import TemplateUploader from "./helpers/TemplateUploader.vue";
import Chevron from "./helpers/Chevron.vue";
import imageProxy from "./../../imageProxy";

export default {
  components: { TemplateUploader, Chevron },
  props: {
    saveMode: {
      type: Boolean,
      default: false,
    },
    currentWidget: {
      type: Object,
      default: () => {},
    },
    templateType: {
      type: String,
      default: "hero",
    },
  },
  data() {
    return {
      showAddNewFolder: false,
      templates: [
        {
          folder: "default",
          components: [],
          open: true,
          expanded: false,
        },
      ],
    };
  },
  methods: {
    image: imageProxy.image,
    toggleTemplates(groupname) {
      // on the save mode, we want to hide the templates unless the user wants to see them, then we expande them
      this.templates.forEach((group) => {
        if (group.folder === groupname) {
          group.expanded = !group.expanded;
          this.$refs["chevronicon_" + group.folder][0].flip();
        }
      });
    },
    chooseTemplate(config) {
      if (this.saveMode) {
        return;
      }
      this.$emit("chooseTemplate", JSON.parse(JSON.stringify(config)));
    },
    addNewFolder() {
      this.templates.forEach((group) => {
        group.open = false;
      });
      this.showAddNewFolder = true;
    },
    toggleFolder(groupname) {
      this.showAddNewFolder = false;
      this.templates.forEach((group) => {
        if (group.folder === groupname) {
          group.open = true;
        } else {
          group.open = false;
        }
      });
    },
    uploadTemplate(request) {
      axios
        .post("/api/admin/pages/components", {
          folder: request.folder,
          name: request.name,
          preview: request.preview,
          config: this.currentWidget,
          type: this.templateType,
        })
        .then(
          () => {
            this.fetchFolderTemplates(request.folder, true);
            this.$toast.success("Template successfully saved");
            this.$emit("templateSaved");
          },
          (err) => {
            this.$toast.error(err.response.data.message);
          }
        );
    },
    deleteTemplate(id, folder) {
      if (!confirm("Are you sure you want to delete this template?")) return;
      axios.delete("/api/admin/pages/components/" + id).then(
        () => {
          this.$toast.success("Template successfully deleted");
          this.fetchFolderTemplates(folder);
        },
        (err) => {
          this.$toast.error(err.response.data.message);
        }
      );
    },
    fetchFolderTemplates(folder, openAfterFetch = false) {
      // For each folder, create an empty object in the this.templates, if it doesn't exist already
      if (!this.templates.find((f) => f.folder === folder)) {
        this.templates.push({
          folder: folder,
          open: false,
          expanded: false,
          components: [],
        });
      } else {
        // Folder exists, clear the components
        this.templates.find((f) => f.folder === folder).components = [];
      }
      // For each folder, fetch the templates
      axios
        .get("/api/admin/pages/components", {
          params: {
            folder: folder,
            type: this.templateType,
          },
        })
        .then((res) => {
          res.data.data.forEach((template) => {
            // this.templates has the structure [{ folder: "default", components: [{},{},{}]}]}
            // Check if the folder already exists
            const folderIndex = this.templates.findIndex(
              (f) => f.folder === folder
            );
            // Folder exists, add the template to it
            this.templates[folderIndex].components.push({
              name: template.name,
              preview: template.preview,
              config: template.config,
              read_only: template.read_only,
              id: template.id,
            });
          });
          if (openAfterFetch === true) {
            this.toggleFolder(folder);
          }
        });
    },
    fetchFolders() {
      // Fetch the template folders from the server
      axios.get("/api/admin/pages/components/folders").then((res) => {
        // sort the folders alphabetically keeping the "default" folder on top
        res.data.sort((a, b) => {
          if (a === "default") {
            return -1;
          }
          if (b === "default") {
            return 1;
          }
          return a.localeCompare(b);
        });

        res.data.forEach((folder) => {
          this.fetchFolderTemplates(folder);
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.templates {
  display: flex;
  flex-direction: column;
  align-items: center;
  .folders {
    display: flex;
    margin-left: -14px;
    .folder {
      margin: 0 50px 0 0;
      width: 90px;
      .title {
        text-transform: capitalize;
        padding: 5px 0;
        font-size: 16px;
        cursor: pointer;
        display: flex;
        font-weight: normal;
        flex-direction: column;
        align-items: center;
        .icon {
          color: #333333;
          fill: #333333;
          width: 50px;
          &.icon-open {
            width: 56px;
            position: relative;
            left: 3px;
          }
        }
        &:hover {
          color: #2980b9;
          .icon {
            fill: #2980b9;
          }
        }
      }
    }
  }

  .delete {
    cursor: pointer;
    &:hover {
      color: red;
    }
  }

  .template {
    position: relative;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;

      font-size: 16px;
      font-weight: bold;
      opacity: 0;
      transition: 0.3s;
    }

    .preview {
      transition: all 300ms ease-in-out;
    }
    &:not(.save-mode) {
      cursor: pointer;
      &:hover {
        .overlay {
          opacity: 1;
        }
        .preview {
          transform: scale(1.05);
        }
      }
    }
    .preview-container {
      overflow: hidden;
      position: relative;
      width: 100%;
      height: 200px;
      border: 1px solid #ccc;
      .preview {
        position: absolute;
        width: 100%;
        height: 200px;
        padding: 10px;
        margin-bottom: 10px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image: var(--preview-url);
      }
    }
  }

  .toggle-templates {
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    color: #ababab;
    fill: #ababab;
  }
  .templates-container {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    &.expanded {
      max-height: 800px;
      overflow: scroll;
    }
  }
}
</style>
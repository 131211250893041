<template>
  <div class="widgetconfigmodelwrapper" :class="{ showing: showing }">
    <div class="widgetConfigContent">
      <div class="config-header">
        <h4>
          <slot name="title"></slot>
        </h4>
        <span>
          <button class="vave-btn btn-blue text-white me-4" @click="()=>{saveTemplateMode = true; templatesMode = true}" v-if="!templatesMode && templateType=='custom-banner'">
            Save Template
            <div class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                  d="M48 96V416c0 8.8 7.2 16 16 16H384c8.8 0 16-7.2 16-16V170.5c0-4.2-1.7-8.3-4.7-11.3l33.9-33.9c12 12 18.7 28.3 18.7 45.3V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96C0 60.7 28.7 32 64 32H309.5c17 0 33.3 6.7 45.3 18.7l74.5 74.5-33.9 33.9L320.8 84.7c-.3-.3-.5-.5-.8-.8V184c0 13.3-10.7 24-24 24H104c-13.3 0-24-10.7-24-24V80H64c-8.8 0-16 7.2-16 16zm80-16v80H272V80H128zm32 240a64 64 0 1 1 128 0 64 64 0 1 1 -128 0z"
                />
              </svg>
            </div>
          </button>

          <button class="vave-btn btn-blue text-white me-4" @click="()=>{saveTemplateMode = false; templatesMode = true}" v-if="!templatesMode && templateType=='custom-banner'">
            <span>
              Load from Template
              <div class="icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                  <path
                    d="M384 480h48c11.4 0 21.9-6 27.6-15.9l112-192c5.8-9.9 5.8-22.1 .1-32.1S555.5 224 544 224H144c-11.4 0-21.9 6-27.6 15.9L48 357.1V96c0-8.8 7.2-16 16-16H181.5c4.2 0 8.3 1.7 11.3 4.7l26.5 26.5c21 21 49.5 32.8 79.2 32.8H416c8.8 0 16 7.2 16 16v32h48V160c0-35.3-28.7-64-64-64H298.5c-17 0-33.3-6.7-45.3-18.7L226.7 50.7c-12-12-28.3-18.7-45.3-18.7H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H87.7 384z"
                  />
                </svg>
              </div>
            </span>
          </button>

          <button class="vave-btn btn-blue text-white me-4" @click="()=>{templatesMode = false; saveTemplateMode = false}" v-if="templatesMode">
            Back to settings
            <div class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                <path
                  d="M125 8h70l10 48.1c13.8 5.2 26.5 12.7 37.5 22L285.6 64 320 123.4l-33.9 30.3c1.3 7.3 1.9 14.7 1.9 22.3s-.7 15.1-1.9 22.3L320 228.6 285.6 288l-43.1-14.2c-11.1 9.3-23.7 16.8-37.5 22L195 344H125l-10-48.1c-13.8-5.2-26.5-12.7-37.5-22L34.4 288 0 228.6l33.9-30.3C32.7 191.1 32 183.6 32 176s.7-15.1 1.9-22.3L0 123.4 34.4 64 77.5 78.2c11.1-9.3 23.7-16.8 37.5-22L125 8zm83 168a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM632 386.4l-47.8 9.8c-4.9 13.4-12 25.8-20.9 36.7l15 44.8L517.7 512l-30.9-34c-7.4 1.3-15 2-22.7 2s-15.4-.7-22.7-2l-30.9 34-60.6-34.4 15-44.8c-8.9-10.9-16-23.3-20.9-36.7L296 386.4V317.6l47.8-9.8c4.9-13.4 12-25.8 20.9-36.7l-15-44.8L410.3 192l30.9 34c7.4-1.3 15-2 22.7-2s15.4 .7 22.7 2l30.9-34 60.6 34.4-15 44.8c8.9 10.9 16 23.3 20.9 36.7l47.8 9.8v68.7zM464 400a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"
                />
              </svg>
            </div>
          </button>

          <button class="vave-btn btn-red text-white" @click="showing = false">
            <i class="fas fa-times"></i>
          </button>
        </span>
      </div>
      <div class="config-body">
        <templates v-if="templatesMode" ref="templates" :currentWidget="currentWidget" :templateType="templateType" :saveMode="saveTemplateMode" @templateSaved="()=>{saveTemplateMode = false}" @chooseTemplate="chooseTemplate" />
        <slot name="body" v-else></slot>
      </div>
      <div class="config-footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Templates from "./Templates.vue";

export default {
  components: { Templates },
  props: {
    currentWidget: {
      type: Object,
      default: () => {},
    },
    templateType: {
      type: String,
      default: "hero",
    },
  },
  data() {
    return {
      showing: false,
      templatesMode: false,
      saveTemplateMode: false,
      icon: "",
    };
  },
  methods: {
    chooseTemplate(config) {
      this.templatesMode = false;
      this.$emit("chooseTemplate", JSON.parse(JSON.stringify(config)));
    },
    show() {
      this.templatesMode = false;
      this.showing = true;
    },
    hide() {
      this.templatesMode = false;
      this.showing = false;
    },
  },
  watch: {
    templatesMode(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.templates.fetchFolders();
        });
      }
    },
  },
  created() {
    const onEscape = (e) => {
      if (this.showing && e.keyCode === 27) {
        this.hide();
      }
    };
    document.addEventListener("keydown", onEscape);
    this.$once("hook:destroyed", () => {
      document.removeEventListener("keydown", onEscape);
    });
  },
};
</script>

<style lang="scss">
.icon {
  font-size: 1em;
  fill: white;
  width: 16px;
  display: inline-block;
  margin-left: 4px;
  position: relative;
  bottom: 2px;
}
.widgetconfigmodelwrapper {
  opacity: 0;
  pointer-events: none;
  position: fixed;
  z-index: 3;
  top: 0px;
  height: 100vh;
  left: 0px;
  width: 100vw;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 500ms ease-in-out;

  label {
    font-weight: bold;
  }

  .widgetConfigContent {
    background: white;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    max-width: 1920px;
    height: 100vh;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 50px 50px rgba(0, 0, 0, 0.5);

    .config-header {
      width: 100%;
      padding: 20px;
      background: rgb(47, 47, 48);
      color: white;
      border-radius: 8px 8px 0 0;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .config-body {
      flex-grow: 2;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 20px;
    }
    .config-footer {
      width: 100%;
      padding: 20px;
      background: #27ae61;
      border-radius: 0 0 8px 8px;
    }
  }

  &.showing {
    opacity: 1;
    pointer-events: all;
  }
}
</style>